import { useEffect } from 'react'

import { dataLayerPush } from '@/utils/tracking/trackingUtils'

type Props = {
  agencyName?: string
  city?: string
  language: Locale
  listingId?: string
  listingPrice?: string
  pageType: string
  propertyIsOwnedByCasavo?: boolean
  propertyStreetAddress?: string
}

export const useTagManager = ({ pageType, ...props }: Props, deps = []): void => {
  useEffect(() => {
    if (typeof window.dataLayer !== 'undefined') {
      dataLayerPush({
        agencyName: undefined,
        city: undefined,
        event: 'casavo.pageview',
        listingId: undefined,
        listingPrice: undefined,
        pageType,
        propertyIsOwnedByCasavo: undefined,
        propertyStreetAddress: undefined,
        ...props,
      })
    }
  }, deps)
}
