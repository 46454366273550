
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __loadNamespaces from 'next-translate/loadNamespaces'
// @ts-ignore
    
import cn from 'classnames'
import { NextPage } from 'next'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'

import { UserAuthentication } from '@/components/auth/UserAuthentication'
import { ConceptImage } from '@/components/ConceptImage'
import { linkBuilder } from '@/components/Link/linkBuilder'
import { MobileBottomContainerFixed } from '@/components/MobileBottomContainerFixed'
import { MobileBottomMenu } from '@/components/MobileBottomMenu'
import { sprinkles } from '@/design-lib/style-theme/sprinkles.css'
import { useTagManager } from '@/hooks/useTagManager'
import { profilePath } from '@/i18n-routes'
import { backgroundColor } from '@/shared-styles/styles.css'
import { buildRelativeLocalizedLink, getValidReferrerOr } from '@/utils/navigation'

const LoginPage: NextPage = () => {
  const router = useRouter()
  const [returnUrl, setReturnUrl] = useState<string | null>(null)

  useEffect(() => {
    const targetUrlAfterLogin = getValidReferrerOr(buildRelativeLocalizedLink(profilePath, router.locale))
    setReturnUrl(targetUrlAfterLogin)
  }, [])

  useTagManager({ language: router.locale, pageType: 'login' })

  return (
    <>
      <Head>
        <title>Login</title>
        <meta content="noindex, nofollow" name="robots" />
      </Head>
      <div
        className={cn(
          backgroundColor.lightBlue,
          sprinkles({ display: 'flex', flexDirection: 'column', paddingBottom: '3xl' })
        )}
        style={{ minHeight: '100%' }}
      >
        <UserAuthentication
          conceptImage={<ConceptImage height={96} name="sofa" width={96} />}
          returnUrl={returnUrl}
          touchPoint="FromMain"
          onLoggedIn={() => {
            window.location.href = returnUrl
              ? returnUrl
              : getValidReferrerOr(linkBuilder.housesForSale.url(router.locale))
          }}
        />
        <footer>
          <MobileBottomContainerFixed>
            <MobileBottomMenu activeButton="login" />
          </MobileBottomContainerFixed>
        </footer>
      </div>
    </>
  )
}

export default LoginPage


// @ts-ignore
    export async function getStaticProps(ctx) {
// @ts-ignore
        
// @ts-ignore
        
// @ts-ignore
        return {
// @ts-ignore
          
// @ts-ignore
          
// @ts-ignore
          props: {
// @ts-ignore
            
// @ts-ignore
            ...(await __loadNamespaces({
// @ts-ignore
              ...ctx,
// @ts-ignore
              pathname: '/login/index',
// @ts-ignore
              loaderName: 'getStaticProps',
// @ts-ignore
              ...__i18nConfig,
// @ts-ignore
              
// @ts-ignore
            }))
// @ts-ignore
          }
// @ts-ignore
        }
// @ts-ignore
    }
// @ts-ignore
  