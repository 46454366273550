import { colors, Description } from '@casavo/base-ui'
import cn from 'classnames'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'

import { useAuthentication } from '@/context/AuthProvider'
import { useSuggestedCriteria } from '@/context/SuggestedCriteriaProvider'
import Icon, { IconKey } from '@/design-lib/components/Icons'
import { sprinkles } from '@/design-lib/style-theme/sprinkles.css'
import { loginPath, profilePath, suggestedFeedPath, suggestedOnboardingPath } from '@/i18n-routes'
import { flexColumn } from '@/shared-styles/styles.css'
import { buildRelativeLocalizedLink, getValidReferrerOr } from '@/utils/navigation'
import { handleClick, handleUserLoginFlow } from '@/utils/tracking/eventsHandlers'

import { linkBuilder } from '../Link/linkBuilder'
import { NotificationCircle } from '../NotificationCircle'

import { mobileBottomMenuWrapper, mobileButton } from './styles.css'

type Props = {
  activeButton: 'search' | 'login' | 'profile' | 'suggested'
}

export const MobileBottomMenu: React.FC<Props> = ({ activeButton }) => {
  const { user } = useAuthentication()
  const { hasSuggestedCriteria } = useSuggestedCriteria()
  const router = useRouter()
  const { lang, t } = useTranslation()
  const [searchHref, setSearchHref] = useState<string>('')
  const hasUserSavedSuggestedCriteria = user && hasSuggestedCriteria

  const handleSuggested = () => {
    if (user) {
      handleClick('ActionBar', 'Auth', 'Suggested')
    } else {
      handleClick('ActionBar', 'Unauth', 'Suggested')
    }
    const path = hasUserSavedSuggestedCriteria ? suggestedFeedPath : suggestedOnboardingPath
    router.push(buildRelativeLocalizedLink(path, lang))
  }

  const suggestedLabel = hasUserSavedSuggestedCriteria
    ? t('action-bar:Suggested_Auth_Button')
    : t('action-bar:Suggested_Unauth_Button')

  useEffect(() => {
    setSearchHref(getValidReferrerOr(linkBuilder.housesForSale.url(lang)))
  }, [])

  return (
    <nav className={mobileBottomMenuWrapper}>
      <MobileBottomMenuButton
        iconKey="HouseCheck"
        isActive={activeButton === 'suggested'}
        label={suggestedLabel}
        showNotification={!hasUserSavedSuggestedCriteria}
        onClick={handleSuggested}
      />

      <MobileBottomMenuButton
        href={searchHref}
        iconKey="Magnify"
        isActive={activeButton === 'search'}
        label={t('action-bar:Search_Button')}
        onClick={() => handleUserLoginFlow('Click', 'SearchButton')}
      />
      {user ? (
        <MobileBottomMenuButton
          href={buildRelativeLocalizedLink(profilePath, lang)}
          iconKey="Avatar"
          isActive={activeButton === 'profile'}
          label={t('action-bar:Profile_Button')}
          onClick={() => handleUserLoginFlow('Click', 'ProfileButton')}
        />
      ) : (
        <MobileBottomMenuButton
          href={buildRelativeLocalizedLink(loginPath, lang)}
          iconKey="User"
          isActive={activeButton === 'login'}
          label={t('action-bar:Login_Button')}
          onClick={() => handleUserLoginFlow('Click', 'LoginButton')}
        />
      )}
    </nav>
  )
}

type MobileBottomMenuButtonProps = {
  href?: string
  iconKey: IconKey
  isActive: boolean
  label: string
  onClick?: () => void
  showNotification?: boolean
}

const MobileBottomMenuButton: React.FC<MobileBottomMenuButtonProps> = ({
  iconKey,
  isActive,
  label,
  showNotification,
  ...props
}) => {
  const ButtonIcon = Icon[iconKey]

  const click = () => {
    props.onClick?.()
    if (props.href) {
      window.location.href = props.href
    }
  }

  return (
    <button
      className={cn(
        mobileButton,
        flexColumn,
        sprinkles({ alignItems: 'center', flex: 1, flexShrink: 0, position: 'relative' })
      )}
      disabled={isActive}
      onClick={click}
    >
      <div>
        <ButtonIcon color={isActive ? colors.orange600 : colors.greyscale400} height={20} width={20} />
      </div>
      <Description color={isActive ? 'orange600' : 'greyscale400'} strong={isActive}>
        {label}
      </Description>
      {showNotification && <NotificationCircle position="top" />}
    </button>
  )
}
